import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Componentes
import { Loading } from './components/Loading';

// Imagens
import logoTexto from './images/logo-texto.svg';
import logoTextoAlt from './images/logo-texto-alt.svg';

// Estilos
import './styles/global.min.css';

const App = () => {
  //! Variáveis iniciais
  const { slug, id } = useParams();
  const [info, setInfo] = useState({
    slug: slug,
    id: id,
  });
  const [defSlugs] = useState([
    {
      id: 'rdr',
      site: 'red-dead-redemption',
    },
    {
      id: 'gta',
      site: 'grand-theft-auto',
    },
    {
      id: 'ac-',
      site: 'assassins-creed-',
    },
    {
      id: 'sod2-je',
      site: 'state-of-decay-2-juggernaut-edition',
    },
    {
      id: 'wwz',
      site: 'world-war-z',
    },
    {
      id: 'tsp',
      site: 'the-stanley-parable',
    },
    {
      id: 'totrel',
      site: 'totally-reliable-delivery-service',
    },
    {
      id: 'fc-',
      site: 'far-cry-',
    },
    {
      id: 'sherlock-holmes',
      site: 'sherlock-holmes-crimes-and-punishments',
    },
    {
      id: 'predator',
      site: 'predator-hunting-grounds',
    },
    {
      id: 'amnesia-tdd',
      site: 'amnesia-the-dark-descent',
    },
    {
      id: 'sr-third',
      site: 'saints-row-the-third-remastered',
    },
    {
      id: 'totalwar',
      site: 'a-total-war-saga-',
    },
    {
      id: 'hzd',
      site: 'horizon-zero-dawn-complete-edition',
    },
    {
      id: 'wtwtlw',
      site: 'where-the-water-tastes-like-wine',
    },
  ]);

  //! Recursos de redirecionamento
  useEffect(() => {
    //? Variáveis
    const defaultHome = 'https://arqueirover.de';
    const subdomain = window.location.hostname.split('.')[0];

    if ((subdomain === 'epic' && info.slug !== undefined) || (subdomain === 'steam' && info.slug === 'epic')) {
      let game = info.slug;
      defSlugs.forEach((e) => {
        if (game.indexOf(e.id) != -1) {
          let newSlug = game.replace(e.id, e.site);
          setInfo({ ...info, slug: newSlug });
        }
      });
    }
    //? Verifica subdomínio, slug e ID
    switch (subdomain) {
      case 'epic': {
        switch (info.slug) {
          case 'free':
          case undefined:
            return (window.location.href = `https://www.epicgames.com/store/pt-BR/free-games?epic_affiliate=fiquenocontrole`);
          default:
            return (window.location.href = `https://store.epicgames.com/fiquenocontrole/${info.slug}`);
        }
      }
      case 'loja':
        {
          if (info.id !== undefined) {
            switch (info.slug) {
              case 'e':
              case 'epic':
                return (window.location.href = `https://store.epicgames.com/fiquenocontrole/${info.id}`);
              case 's':
              case 'steam':
              case 'app':
                return (window.location.href = `https://store.steampowered.com/app/${info.id}`);
              case 'curator':
                return (window.location.href = `https://store.steampowered.com/${info.slug}/${info.id}`);
              case 'franchise':
                return (window.location.href = `https://store.steampowered.com/${info.slug}/${info.id}`);
              case 'bundle':
                return (window.location.href = `https://store.steampowered.com/${info.slug}/${info.id}`);
              case 'hs':
                return (window.location.href = `https://www.humblebundle.com/store/${info.id}?partner=fiquenocontrole`);
              case 'hg':
                return (window.location.href = `https://www.humblebundle.com/game/${info.id}?partner=fiquenocontrole`);
              case 'gog':
                return (window.location.href = `https://www.gog.com/game/${info.id}`);
              case 'gmg':
                return (window.location.href = `https://www.greenmangaming.com/pt/games/${info.id}`);
              default: {
                if (!window.location.search) {
                  window.location.href = `${defaultHome}`;
                }
              }
            }
          } else {
            if (!window.location.search) {
              window.location.href = `${defaultHome}`;
            }
          }
        }
        break;
      default: {
        if (!window.location.search) {
          window.location.href = `${defaultHome}`;
        }
      }
    }
  });

  return (
    <div>
      <main className="main">
        <picture>
          <source media="(min-width: 600px)" srcSet={logoTexto} className="logo" />
          <img src={logoTextoAlt} className="logo" />
        </picture>
        {!window.location.search && <Loading />}
      </main>
    </div>
  );
};

export default App;
