export const Loading = () => {
  return (
    <div className="el-loading">
      <div className="el-loading-gif">
        <img src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" />
      </div>
      <div className="el-loading-text">Verificando redirecionamento...</div>
    </div>
  );
};
